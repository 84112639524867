import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
//import { data } from "../../data";
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { fCurrency } from "utils/formatNumber";
import { ExpandMore } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { StoreContext } from "../../layout/HomeLayout/index";
import { path } from "config/path";

const CAT_COLOR_OPTION = 'buttercream';

const colors = ['Red', 'Blue', 'Yellow', 'Pink', 'Purple', 'Green', 'Others'];
// PRODUCT INFO
const StaffInfo = () => {
	// HOOKS
	const params = useParams();
	const theme = useTheme();
	const { store, cart, setCart } = useContext(StoreContext);
	const location = useLocation();

	// DATA
	//const product = data.find((item) => item.id === Number(params.id));
	const [quantity, setQuantity] = useState(1);
	const [products, setProducts] = useState([]);
	const [pidx, setPidx] = useState(0);
	const [selColor, setSelColor] = useState("");
	const [customColor, setCustomColor] = useState("");	
	const [toppings, setToppings] = useState([]);
	const [selTopping, setSelTopping] = useState(0);
	const [toppingNote, setToppingNote] = useState("");
	const [totalPrice, setTotalPrice] = useState(0);


	useEffect(() => {
		async function getMenu() {
			const res = await fetch(`${path.ciHost}/index.php/api/v1/product/id/` + params.id);
			const products = await res.json();
			// console.log(products);
			setPidx(0);
			setProducts(products);
			if (products[0]?.subcategory == CAT_COLOR_OPTION) setSelColor(colors[0]);
		}
		getMenu();
	}, [params.id]);

	// RENDER
	return products.length > 0 && products[pidx] ? (
		<Box>
			{/* BreadCrumbs */}
			<Breadcrumbs sx={{ pt: 2, px: 2 }}>
				{/* <Link to="/">Home</Link> */}
				<Link to={`/staff`}>Staffs</Link>
				<Typography sx={{ textTransform: "capitalize" }}>{products[pidx].name}</Typography>
			</Breadcrumbs>
			<Stack sx={{ p: 2 }} spacing={1}>
				{/* Title */}
				<Typography variant="h2">{products[pidx].name}</Typography>				
				<Typography variant="h3" fontWeight={"normal"} sx={{ textTransform:'capitalize'}}>
					{products[pidx].subcategory}
				</Typography>
			</Stack>
			<Stack direction={{xs:'colume', md:'row'}} sx={{ alignItems:'center', justifyContent:'center' }}>
				{/* Image */}
				<Box display='flex' sx={{ px: 2, maxWidth: 400 }}>
					<img
						src={products[pidx].image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${products[pidx].image}` : `${path.basename}/images/user-circle.svg`}
						alt={products[pidx].name}
						style={{ width: "100%", height: "auto" }}
					/>
				</Box>
				<Stack spacing={2} sx={{ mt: 2 }}>
					<Stack>
						{/* Description */}
						{products[pidx].note && (
							<Accordion disableGutters square defaultExpanded>
								<AccordionSummary expandIcon={<ExpandMore />}>
									<Typography variant="h4">Description</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography sx={{ textAlign: "justify" }} variant="body1">
										{products[pidx].note}
									</Typography>
								</AccordionDetails>
							</Accordion>
						)}						
					</Stack>
				</Stack>
			</Stack>
		</Box >
	) : (
		<>
			<h1>Loading...</h1>
		</>
	);
};

export default StaffInfo;
