import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { MenuMCakeCategoryGrid, MenuMCakeGrid } from "components/page/menu/cake";
import { useParams, Link } from "react-router-dom";
import { StoreContext } from "../../layout/HomeLayout/index";
import { useContext, useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";

// MENU
const Menu = () => {
	// HOOKS
	const { category } = useParams();
	// DATA
	const { allmenu } = useContext(StoreContext);
	const [cat, setCat] = useState([]);
	const [menu, setMenu] = useState([]);
	// USE EFFECTS
	useEffect(() => {
		let categories = [];
		allmenu.forEach((values, keys) => {
			categories.push({ name: keys, sub: values });
			if (category) {
				let selmenu = values.get(category);
				if (selmenu) {
					//console.log(selmenu);
					setMenu(selmenu);
				}
			}
		});
		setCat(categories);
	}, [allmenu, category]);
	// RENDER
	return (
		<Box sx={{ pt: 1 }}>
			{/* <Accordion disableGutters square elevation={1} sx={{ m: 1 }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="h5">
						Allergy and Safety Information
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{ textAlign: "justify" }} variant="body1" fontWeight="700">
						For your transparency and safety, please be aware of the following:
						<br />
						* Nut Traces: All our cakes are prepared in a bakery where traces of nuts may be present.
						<br />
						* Ingredients: All our cakes contain eggs and milk and may contain traces of nuts.
						<br />
						* Cross-Contamination: We take every precaution to minimize the risk of cross-contamination, but customers with nut allergies should exercise caution.
						<br />
						<br />
						We advise anyone with specific dietary concerns or allergies to contact us for more detailed information. Your health and well-being are our top priorities,
						and we are committed to ensuring a safe and enjoyable experience with our products.
						<br />
					</Typography>
				</AccordionDetails>
			</Accordion> */}
			<Link to="/price">
				<Typography variant="h2" sx={{ p: 2 }}>
					{category && (<span>&lt;</span>)} Services & Price
				</Typography>
			</Link>
			{!category ? 
				cat.map((category, index) => { 	
					return (
						<MenuMCakeCategoryGrid key={index} name={category.name} category={category.sub} /> 
					)
				}) 
				: <MenuMCakeGrid menus={menu} />
			}
		</Box>
	);
};

export default Menu;
