import { 
	Box, Typography, Grid, Stack, Divider,
	Accordion, AccordionDetails, AccordionSummary, 
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
 } from "@mui/material"
 import { ExpandMore } from "@mui/icons-material";
import { StoreContext } from "../../layout/HomeLayout/index";
import { useContext, useEffect, useState } from "react";
import { path } from "config/path";
import { useNavigate, useParams } from "react-router-dom";
import BookBtn from "components/ui/bookbtn";

const PriceTable = ({ sizes, colors, prices }) => {
	if (sizes.length<1 || colors.length<1) return (
		<Typography variant="h4" sx={{ pl:'2em', py:'1em'}}>Price : POA</Typography>
	);

	return (
		<TableContainer sx={{ pb: 2 }}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell sx={{ fontWeight: 700 }}>&nbsp;</TableCell>
						{ colors && colors.length>0 && colors.map((color, index) => (
							<TableCell key={index} sx={{ fontWeight: 700, px:1 }} align="center">
								{color}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{ sizes && sizes.length>0 && sizes.map((size, index) => {
						return (
							<TableRow key={index}>
								<TableCell sx={{ fontWeight: 700, px:1 }}>{size}</TableCell>
								{colors.map((color, index) => {
									const key = `${color}_${size}`;
									return prices[key] ? (
										<TableCell key={index} align="center">
											<Typography variant="body1" sx={{ 
												fontWeight:'800',
											}}>
												£{prices[key].price}
											</Typography>
										</TableCell>
									) : (
										<TableCell key={index}></TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const PriceList = ({menus}) => {
	const [menu, setMenu] = useState({name:'price', main:{},  items:[]});

  useEffect(() => {	
		let cat = {name:'price', main:{}, items:[]};
		let item;
		let premodel = '';
		menus.map((service)=>{
			if (service.model != premodel) {
				item = new Object();
				item.name = service.name;
				item.main = service;
				item.sizes = new Set();
				item.colors = new Set();
				item.prices = {};				
				cat.items.push(item);
				premodel = service.model
			}
			else if (service.model=='') {
				cat.name = service.name;
				cat.main = service;
			}
			else if (service.price>0) {
				item.sizes.add(service.size + service.name1);
				item.colors.add(service.color);
				const key = `${service.color}_${service.size + service.name1}`;
				item.prices[key] = { id: service.id, price: service.price };			
			}
		})
    //console.log(cat);
		setMenu(cat); 
	}, [menus]);

	return (
		<Stack>
			{menu && menu.items && menu.items.map((service,index) => (
				<Box key={index}>
					<Typography variant="h4" sx={{ pl:'0em'}}>&#9654; {service.name}</Typography>
					<Typography>{service.main.name1}</Typography>
					{ service.sizes && service.colors && <PriceTable sizes={Array.from(service.sizes)} colors={Array.from(service.colors)} prices={service.prices} /> }
					<Typography sx={{ fontSize:'1.5em', whiteSpace: "pre-wrap"}}>{service.main.note}</Typography>
				</Box>				
			))}
		</Stack>
	)
}

const Price = () => {
  const { store, allmenu } = useContext(StoreContext);
	const [ prices, setPrices ] = useState();
  const navigate = useNavigate();

	useEffect(()=>{
		let categories = [];		
		allmenu.forEach((values, keys) => {
			if (keys=='price') {
				values.forEach((value, key) => {
			  	categories.push({ key: key, name: value[0].name, note:value[0].note, sub: value, image:value[0].image });
				})
      }
		});
		setPrices(categories);
	},[allmenu]);

  return (
    <Box display='block' sx={{ p:'2em 1em', minHeight:600}}>
			<Box sx={{ textAlign:'center', px:'3.2em' }}>
				<BookBtn />
			</Box>
      <Typography variant="h2">Our Services & Price</Typography>
      <Divider />
      {/* <Grid container spacing={2} sx={{ mt: 0 }}>
				{prices && prices.map((submenu, index) => {
					const key = `${submenu.category}_${index}`;
					return (
						<Grid key={key} item xs={6} md={4} onClick={() => handleClick(submenu.key)}>
							<Stack alignItems="center" spacing={1}>
								<Box display='flex' width='100%' sx={{ 
									flexDirection:'column',
								}}>
									<ImageBox 
                    imageUrl={`${path.imageServer}${path.imageDirectory}/${store.key}/${submenu.image}`} 
                    isBg={true} 
                    br='1em'
                    opc='0.2'
                  >
										<Box display='flex' height='20em' sx={{
											alignItems:'center',
											justifyContent:'center',
                      p:'1em'
										}}>
											<Typography variant="h1" sx={{ 
												fontSize:{xs:'2em', md:'3em'},
                        textTransform:'capitalize',
												color:'white',
												textShadow:'-1px -1px 0 #111, 1px -1px 0 #111, -1px 1px 0 #111,1px 1px 0 #111;'
											}}>
												{submenu.name}
											</Typography>
										</Box>
									</ImageBox>
								</Box>
              </Stack>
						</Grid>
					);
				})}
			</Grid> */}
      <Stack width='100%'>
				{ prices && 
					prices.map((items, idx) => (
						<Stack key={idx} >
							<Typography variant="h2" sx={{ 
								textTransform:'capitalize',
								pt:'1em', 
								pb:'0.5em',
								textDecoration:'underline'
							}}>
								{items.name}
							</Typography>
							<Typography variant="body1" sx={{ 
								fontSize:'1.7em',  
								lineHeight:'1.2',
								pb:'1em',
								whiteSpace: "pre-wrap"
							}}>
								{items.note }
							</Typography>
							<PriceList menus={items.sub} />
						</Stack>
					))
				}
				<Accordion disableGutters square>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography variant="h3">Clients Notice</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ textAlign: "justify" }} variant="body1">
							Depending on the length and thickness of hair, prices may change. Clients with thicker or longer hair may charge more. We cannot carry out perm on clients who already have highly damaged or bleached hair. However, if you really want to get it done; you have to visit the salon in advance for a consultation. 
						</Typography>
					</AccordionDetails>
				</Accordion>
				
				<Accordion disableGutters square>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography variant="h3">Cancellation Policy</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ textAlign: "justify" }} variant="body1" fontWeight="500">
							Jubilee hair salon requires 24 hours' notice for hair cuts, and a 48 hours' notice for chemical treatments
							<br />
							If you have paid the deposit for the appointment, let us know by text 07902317522 ,in advance at least 48 hours before about cancelling your appointment and we will give you the refund for the deposit.
							<br />
							If you tell us that you cannot attend your appointment after 24hours, we cannot give you the refund of the deposit. In addition to this, if you come 15 minutes or later to your allocated appointment, we cannot process your hair appointment and also cannot give you the refund of your deposit. 
							<br />
							Perm and Straightening Policy: If you come to your appointment and find out that your hair is not suitable on the day for the process without having your hair checked via text message on 079023 17522 or visiting the salon, the deposit is non refundable. 
							<br/>
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Stack>
    </Box>
  );
};

export default Price;