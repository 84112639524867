import { Box, Typography, Grid, Stack, Divider } from "@mui/material"
import { StoreContext } from "../../layout/HomeLayout/index";
import { useContext, useEffect, useState } from "react";
import { path } from "config/path";
import { useNavigate, useParams } from "react-router-dom";
import BookBtn from "components/ui/bookbtn";

const StaffList = ({list,store,handleClick}) => {
	return list ? (
		<Grid container spacing={2}>
		{ 
			list.map((menu, index) => {
				return (
					<Grid item key={index} xs={6} md={3} onClick={() => handleClick(menu.id)}>
						<Stack spacing={1} sx={{ mb: 0 }}>
							<Box
								sx={{
									borderRadius: '2em',
									width: "100%",
									height: {xs:180, md:200},
									overflow: "hidden",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									border: "1px solid #ccc",
								}}>
								<img 
									src={menu.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${menu.image}`: `${path.basename}/images/user-circle.svg`}
									alt={menu.name} style={{ 
										objectFit: "cover", 
										width: "100%",
										//transform: 'translateY(-10%)'
									}} 
								/>
							</Box>
							<Stack sx={{ alignItems:'center'  }}>
								<Typography variant="h4" sx={{ textTransform: "capitalize", textAlign:'center' }}>
									{menu.name}
								</Typography>
							</Stack>
						</Stack>
					</Grid>
				)
			})
		}
		</Grid> 
	) : (<></>)
}

const Staff = () => {
  const { store, allmenu } = useContext(StoreContext);
	const [ staffs, setStaffs ] = useState();
  const navigate = useNavigate();
  const handleClick = (model) => {
		navigate(`/staff-info/${model}`);
	};

	useEffect(()=>{
		let categories = [];		
		allmenu.forEach((values, keys) => {
			if (keys=='staff') {
				values.forEach((value, key) => {
			  	categories.push({ name: key, sub: value });
				})
      }
		});
		setStaffs(categories);
	},[allmenu]);

  return (
    <Box display='block' sx={{ p:'2em 1em', minHeight:600}}>
			<Box sx={{ textAlign:'center', px:'3.2em' }}>
				<BookBtn />
			</Box>
      <Typography variant="h2">Our Staffs</Typography>
      <Divider />
      <Stack width='100%'>
				{ staffs && 
					staffs.map((items, idx) => (
						<Stack key={idx} >
							<Typography variant="h3" sx={{ 
								textTransform:'capitalize',
								pt:'1em', 
								pb:'0.5em',
							}}>
									{items.name}
							</Typography>
							<StaffList list={items.sub} store={store} handleClick={handleClick} />	
						</Stack>
					))
				}
			</Stack>
    </Box>
  );
};

export default Staff;