const gqhost = 
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4001/graphql'
    : process.env.REACT_APP_GRHOST + '/graphql';

const imageServer = 
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4001'
    : process.env.REACT_APP_GRHOST;

const ciHost = 
  process.env.NODE_ENV === 'development'
    ? 'http://stock.local.tst/ci' :
    process.env.REACT_APP_CIHOST;

const adminPath = '/816316aa-2d28-4dcb-90a6-f1bd9be46fe4';

export const qrcodeHost =
  process.env.REACT_APP_APPURL + process.env.REACT_APP_BASE;

export const path = {
  imageServer,
  gqhost,
  ciHost,
  basename: process.env.REACT_APP_BASE,
  defaultPath: '/store/staffs',
  // defaultPath: '/dashboard',
  imageDirectory: '/img',
  myip: 'https://api.ipify.org?format=json', //'https://api.myip.com/', //
  urls: {
    default: '/',
    home:'/home',
    about:'/about',
    menu:'/menu',
    price:'/price',
    priceInfo:'/price-info',
    staff:'/staff',
    staffInfo:'/staff-info',
    productInfo:'/product-info',
    checkout:'/checkout',
    contact:'/contact',
    privacy:'/privacy',
    terms:'/terms',
    login: '/login',
    register: '/register',
    profile: '/profile',
    // dashboard: '/dashboard',
    // qrcodeItem: '/qrcode/item',
    inventory: '/store',
    inventoryItems: '/store/staffs',
    inventoryItemInfo: '/store/staff-info',
    prices: '/store/prices',
    priceItemInfo: '/store/price-info',
    inventoryOrders: '/orders',
    // inventoryItemInOut: '/inventory/outgoing',
    // inventoryWarehouse: '/inventory/warehouse',
    settingStore: '/setting/store',
    settingStoreEdit: '/setting/store-edit',
    settingMembers: '/setting/members',
    settingMember: '/setting/member',
  },
  admin: {
    default: adminPath,
    login: adminPath + '/login',
    setting: adminPath + '/setting',
    list: adminPath + '/list',
  },
};
