import { Box, Typography } from "@mui/material"
import { TermsAndConditions } from "components/page/menu/tnc";
import BookBtn from "components/ui/bookbtn";

const Terms = () => {
  return (
    <Box display='block' sx={{ p:'2em 1em', minHeight:600}}>
      <Box sx={{ textAlign:'center', px:'3.2em' }}>
				<BookBtn />
			</Box>
      <TermsAndConditions />
    </Box>
  );
};

export default Terms;