import { Box, Divider, Grid, Stack, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { path } from 'config/path';
import { StoreContext } from "layout/HomeLayout";
import { ExpandMore } from "@mui/icons-material";
import PriceTable from "../pricetable";

// CAKE GRID MENU
const MenuMCakeGrid = ({menus}) => {
	// HOOKS
	const { category } = useParams();
	const { store } = useContext(StoreContext);
	const navigate = useNavigate();
	// DATA
	const [menu, setMenu] = useState({main:category, items:[]});

	// FUNCTIONS
	const handleClick = (model) => {
		navigate(`/product-info/${model}`);
	};

	// USE EFFECTS
	useEffect(() => {
		console.log(menus);
		let cat = {main:{name:category}, items:[]};
		let sub = {};
		let premodel = '';
		menus.map((menu)=>{
			if (menu.model=='') {
				cat.main = menu;
			}
			else if (menu.model != premodel) {
				sub = {name : menu.name, image :menu.image, model:menu.model, menus:[]};
				cat.items.push(sub);
				premodel = menu.model
			}
			else {
				sub.menus.push(menu);
			}
			
		})
		console.log(cat);
		setMenu(cat); 
	}, [menus]);

	// RENDER
	return (
		<Box sx={{ mb: 4, px: 2 }}>
			{ menu.main.note ? (
				<Accordion disableGutters square>
					<AccordionSummary expandIcon={<ExpandMore />} sx={{p:0, minHeight:0 }}>
						<Typography variant="h3">{menu.main.name}</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{p:0}}>
						<Typography sx={{ textAlign: "justify" }} variant="body1">
							{menu.main.note}
						</Typography>
					</AccordionDetails>
				</Accordion>
			) : (
				<>
					<Typography variant="h3" sx={{ mt: 2, textTransform: "capitalize" }}>
						{menu.main.name}
					</Typography>
					<hr/>
				</>
			)}
			{/* Divider */}
			{/* <Divider sx={{ borderBottomWidth: 2, mt: 2 }} /> */}
			<Typography variant="h4" color='red'>{menu.main.name1}</Typography>

			{/* {
				menu.items.map((menu, index) => (
					<>
					<Typography variant="h3">{menu.name}</Typography>
					<PriceTable item={menu.menus} />
					</>
				))
			} */}
			{/* Grid */}
			<Grid container spacing={2} sx={{ mt: 2 }}>
				{
					menu.items.map((menu, index) => (
						<Grid key={index} item xs={6} md={3} onClick={() => handleClick(menu.model)}>
							<Stack spacing={1} sx={{ mb: 0 }}>
								<Box
									sx={{
										borderRadius: '2em',
										width: "100%",
										height: {xs:180, md:200},
										overflow: "hidden",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: "1px solid #ccc",
									}}>
									<img 
										src={menu.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${menu.image}`: `${path.basename}/images/icon-product.svg`}
										alt={menu.name} style={{ 
											objectFit: "cover", 
											width: "100%",
											//transform: 'translateY(-10%)'
										}} 
									/>
								</Box>
								<Stack sx={{ alignItems:'center'  }}>
									<Typography variant="body1" sx={{ textTransform: "capitalize", textAlign:'center' }}>
										{menu.name}
									</Typography>
								</Stack>
							</Stack>
						</Grid> 
					))
				}
			</Grid>
		</Box>
	);
};

export default MenuMCakeGrid;
