import { 
	Accordion, AccordionDetails, AccordionSummary, 
	Box, Breadcrumbs, Button, FormControl, Grid,
	InputLabel, MenuItem, Select, Stack, TextField, Typography,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
//import { data } from "../../data";
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { fCurrency } from "utils/formatNumber";
import { ExpandMore } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { StoreContext } from "../../layout/HomeLayout/index";
import { path } from "config/path";

const PriceTable = ({ items, handleClick }) => {
	// data
	const [sizes, setSizes] = useState([]);
	const [colors, setColors] = useState([]);
	const [prices, setPrices] = useState({});

	useEffect(() => {
		if (items) {
			const types = new Set();
			const stylists = new Set();
			const prices = {};
			items.map((service, index) => {
				if (service.price>0) {
					types.add(service.size);
					stylists.add(service.color);
					const key = `${service.color}_${service.size}`;
					prices[key] = { id: index, price: service.price };
				}
			})
			
			setSizes(Array.from(types));
			setColors(Array.from(stylists));
			setPrices(prices);
		}
	}, [items]);

	if (sizes.length<1) return (
		<Typography variant="h4" sx={{ pl:'2em', py:'1em'}}>Price : POA</Typography>
	);

	return (
		<TableContainer sx={{ pb: 2 }}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell sx={{ fontWeight: 700 }}>#</TableCell>
						{colors.map((color, index) => (
							<TableCell key={index} sx={{ fontWeight: 700, px:1 }} align="center">
								{color}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{sizes.map((size, index) => {
						return (
							<TableRow key={index}>
								<TableCell sx={{ fontWeight: 700, px:1 }}>{size}</TableCell>
								{colors.map((color, index) => {
									const key = `${color}_${size}`;
									return prices[key] ? (
										<TableCell key={index} align="center" sx={{ color: "primary.main", cursor: "pointer" }} onClick={() => handleClick(prices[key].id)}>
											£{prices[key].price}
										</TableCell>
									) : (
										<TableCell key={index}></TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

// PRODUCT INFO
const ProductInfo = () => {
	// HOOKS
	const params = useParams();
	const theme = useTheme();
	const { store, cart, setCart } = useContext(StoreContext);
	const location = useLocation();

	// DATA
	//const product = data.find((item) => item.id === Number(params.id));
	const [quantity, setQuantity] = useState(1);
	const [products, setProducts] = useState([]);
	const [pidx, setPidx] = useState(0);
	const [selColor, setSelColor] = useState("");
	const [customColor, setCustomColor] = useState("");	
	const [toppings, setToppings] = useState([]);
	const [selTopping, setSelTopping] = useState(0);
	const [toppingNote, setToppingNote] = useState("");
	const [totalPrice, setTotalPrice] = useState(0);

	useEffect(() => {
		async function getMenu() {
			const res = await fetch(`${path.ciHost}/index.php/api/v1/product/model/` + params.id + '/' + store.id);
			const services = await res.json();
			//console.log(services);	
			setPidx(0);
			setProducts(services);
		}
		getMenu();
	}, [params.id]);

	// FUNCTIONS
	const handleAddToCart = () => {
		const selToppingDetails = toppings.find((topping) => topping.id === selTopping);
		let toppingDetails = null;
		if (selToppingDetails) {
			toppingDetails = {
				id: selTopping,
				size: selToppingDetails.size,
				price: selToppingDetails.price,
				image: selToppingDetails.image,
				note: toppingNote,
			};
		}
		const newCartItem = [...cart, { 
			...products[pidx], 
			quantity, 
			size: products[pidx].size, 
			topping: toppingDetails,
			color:selColor 
		}];
		// console.log(newCartItem);
		setCart(newCartItem);
	};

	const handleQty = (type) => {
		if (quantity <= 1 && type === "minus") {
			return;
		}
		if (type === "add") {
			setQuantity(quantity + 1);
		} else {
			setQuantity(quantity - 1);
		}
	};

	// USE EFFECTS

	// useEffect(() => {
	// 	const selToppingObj = toppings.find((topping) => topping.id === selTopping);
	// 	const toppingPrice = selToppingObj?.price || 0;
	// 	const cakePrice = products[pidx]?.price || 0;
	// 	setTotalPrice(quantity * (Number(cakePrice) + Number(toppingPrice)));
	// }, [toppings, selTopping, quantity, products, pidx]);

	// RENDER
	return products.length > 0 && products[pidx] ? (
		<Box>
			{/* BreadCrumbs */}
			<Breadcrumbs sx={{ py: 2, px: 2 }}>
				<Link to={`/price`}>Service & Price</Link>
				<Link to={`/menu/${products[pidx].subcategory}`}>
					<Typography sx={{ textTransform: "capitalize" }}>{products[pidx].subcategory}</Typography>
				</Link>
				<Typography sx={{ textTransform: "capitalize" }}>{products[pidx].name}</Typography>
			</Breadcrumbs>
			{/* Title */}
			<Stack spacing={1}>
				<Typography variant="h3" sx={{ pl: 2 }} >{products[pidx].name}</Typography>
				<Typography variant="body1" sx={{ pl:2}}>{products[pidx].name1}</Typography>
				<PriceTable items={products} handleClick={setPidx} />
			</Stack>
			<Stack direction={{xs:'colume', md:'row'}} sx={{ alignItems:'center', justifyContent:'center' }}>
				{/* Image */}
				{/* <Box display='flex' sx={{ 
					px: 2, minWidth: 400, maxWidth: 400, 
				 }}>
					<img
						src={ products[pidx].image ? 
							`${path.imageServer}${path.imageDirectory}/${store.key}/${products[pidx].image}` : 
							( products[0].image ? 
								`${path.imageServer}${path.imageDirectory}/${store.key}/${products[0].image}` :
								`${path.basename}/images/icon-product.svg`
							)}
						alt={products[pidx].name}
						style={{ width: "100%", height: "auto", borderRadius:"1em" }}
					/>
				</Box> */}
				{ products[pidx].price>0 && (
				<Stack sx={{ p: 2 }} spacing={1}>
					<Typography variant="h3" fontWeight={"normal"} textTransform='capitalize'>
						{products[pidx].color}/{products[pidx].size} : £{fCurrency(products[pidx].price)}
					</Typography>
				</Stack>
				) }
				<Stack spacing={2} sx={{ mt: 2, width:'100%' }}>
					
					<Stack
						sx={{
							"& .MuiAccordion-root": {
								borderTop: "1px solid",
								borderColor: theme.palette.grey[500],
								"&::before": { display: "none" },
								"&:last-child": { borderBottom: "1px solid", borderColor: theme.palette.grey[500] },
							},
						}}>
						{/* Description */}
						{products[pidx].note && (
							<Accordion disableGutters square defaultExpanded>
								<AccordionSummary expandIcon={<ExpandMore />}>
									<Typography variant="h4">Description</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography sx={{ textAlign: "justify" }} variant="body1">
										{products[pidx].note}
									</Typography>
								</AccordionDetails>
							</Accordion>
						)}
						
						<Accordion disableGutters square defaultExpanded>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant="h4">Clients Notice</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography sx={{ textAlign: "justify" }} variant="body1">
									Depending on the length and thickness of hair, prices may change. Clients with thicker or longer hair may charge more. We cannot carry out perm on clients who already have highly damaged or bleached hair. However, if you really want to get it done; you have to visit the salon in advance for a consultation. 
								</Typography>
							</AccordionDetails>
						</Accordion>
						
						<Accordion disableGutters square defaultExpanded>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant="h4">Cancellation Policy</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography sx={{ textAlign: "justify" }} variant="body1" fontWeight="500">
									Jubilee hair salon requires 24 hours' notice for hair cuts, and a 48 hours' notice for chemical treatments
									<br />
									If you have paid the deposit for the appointment, let us know by text 07902317522 ,in advance at least 48 hours before about cancelling your appointment and we will give you the refund for the deposit.
									<br />
									If you tell us that you cannot attend your appointment after 24hours, we cannot give you the refund of the deposit. In addition to this, if you come 15 minutes or later to your allocated appointment, we cannot process your hair appointment and also cannot give you the refund of your deposit. 
									<br />
									Perm and Straightening Policy: If you come to your appointment and find out that your hair is not suitable on the day for the process without having your hair checked via text message on 079023 17522 or visiting the salon, the deposit is non refundable. 
									<br/>
								</Typography>
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Stack>
			</Stack>
		</Box >
	) : (
		<>
			<h1>Loading...</h1>
		</>
	);
};

export default ProductInfo;
