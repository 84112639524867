import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import StarIcon from '@mui/icons-material/Star';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviews = [
  {
    customer: 'Alice Khor',
    comment: "Amazing experience. Got my hair cut and straightened by Anna, who did the whole process meticulously. She managed to make my hair look naturally straight and with lovely volume on the top! I have done many hair straightening treatments over the years in different places, but this has been the best experience yet!",
  },
  {
    customer: 'Akit Gurung',
    comment:`Been going here for more than a year. Every time I visit here the experience is so amazing!

The staff and the receptionists’ are very welcoming and helpful.

The staff KNOW HOW TO CUT HAIR - if you don’t know what to get, they will guide you accordingly and tailor to your needs. They are adept in their skills, and will make you satisfied.

Despite the high price, I would say it’s worth it.

Kudos to Sue, Yuka and Yukiko!!`,
  },
  {
    customer: 'Mij Q',
    comment:'My hair was damaged, I came to this salon, however I was nervous because everyone was Asian, I  did not expect how incredible it actually looked after getting my hair done I think the person that done my hair was called juay, he was excellent! N the best perm I ever had I will definitely come back n stick to this salon! They are so kind also I don’t no if I got a discount or is it just the salon, the price was very worth it !! I will strongly recommend and stick to this salon 👍',
  },
  {
    customer: 'Joana Ventura',
    comment:"Fantastic service and results! Anna not only made me feel welcome as she did an amazing job with my hair. Wispy layers, face framing and overall tailoring it to my face shape. If you want quality - go to Jubilee.",
  },
  {
    customer: 'Jordan Cheah',
    comment:"Been going to Jubilee for over a year now and that's not going to change any time soon! Always get my hair cut/permed with Joy who is so kind, attentive and really works with you to get your hair exactly how you want it (and better!). Couldn't be happier :) Shoutout to June too!",
  },
];

const CustomerReview = ({step}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '22em', 
        display: 'inline-block',
        overflow:'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#4A2E1C', // Optional: semi-transparent background for overlay
          color: 'white', // Text color
          textAlign: 'center', // Center text alignment
        }}
      >
        <Box display='block'>
          <Typography variant='h2' sx={{ 
            fontSize:'2em',
            color:'white',
            pt:'1em',
            }}>
              {step.customer}
          </Typography>
          <Box>
            <StarIcon sx={{ color : '#D4AF37' }}/>
            <StarIcon sx={{ color : '#D4AF37' }}/>
            <StarIcon sx={{ color : '#D4AF37' }}/>
            <StarIcon sx={{ color : '#D4AF37' }}/>
            <StarIcon sx={{ color : '#D4AF37' }}/>
          </Box>
          <Box sx={{ height : '9em'}}>
            <Typography variant='body1' sx={{ 
              mt:'1em',
              px:'2em', 
              fontSize:'1.2em',
              fontStyle:'italic',
            }}>
                {step.comment.length>200 ? step.comment.substring(0,200)+'...' : step.comment }
            </Typography>
          </Box>
        </Box>
        <Button 
          href="https://search.google.com/local/writereview?placeid=ChIJ4SgGqDIbdkgRkvHRllSB2yM" 
          target='_blank'
          variant='contained' 
          sx={{ mb:'2em', backgroundColor:'white', color:'black'}} 
        >
          Leave Your Review
        </Button>
      </Box>
    </Box>
  )
}

function Review() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviews.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ 
      pt : 0, //{xs:0, md:'3em'},
      pl : 0, //{xs:0, md:'3em'} 
    }}>
      <AutoPlaySwipeableViews
        interval={8000}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviews.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <CustomerReview step={step} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </Box>
  );
}

export default Review;
