import { CurrencyPound } from '@mui/icons-material';
import { path } from 'config/path';

const price = {
    type: 'group',
    children: [
        {
            id: 'price',
            title: 'Prices',
            type: 'item',
            url: path.urls.prices, //path.urls.inventoryOrders,
            icon: CurrencyPound
        }
    ]
}

export default price;