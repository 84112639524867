// helper
import { checkAuthLevel } from "helpers/storage";
// menu items
import dashboard from "./dashboard";
import menu from "./menu";
import setting from "./setting";
import admin from "./admin";
import price from "./price";
//import order from "./order";

const menuItems = {
	// items: checkAuthLevel() === 'admin' ? [admin] : [dashboard, menu, setting],
	items: checkAuthLevel() === "admin" ? [admin] : [menu, price, setting],
};

export default menuItems;
