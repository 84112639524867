import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
	Box, Button, Divider, Stack, TextField, Checkbox,
	Typography, useTheme, FormGroup, FormControlLabel,
	Modal, IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { fCurrency } from "utils/formatNumber";
import { StoreContext } from "layout/HomeLayout/index";
import { useNavigate, Link } from "react-router-dom";
import { path } from 'config/path';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Moment from "moment";
import { Add, Remove, Close } from "@mui/icons-material";
import { addDays, addMinutes } from "date-fns";
import { TermsAndConditions } from "./tnc";

const LS_USERINFO = 'userinfo';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
	height : '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
	//overflow:'scroll',
};

// CHECKOUT FORM
const CheckoutForm = () => {
	// HOOKS
	const theme = useTheme();
	const { store, cart, setCart } = useContext(StoreContext);
	const navigate = useNavigate();
	// DATA
	const minOrderDate = addDays(new Date(), 2);
	const minTime = new Date().setHours(9, 0, 0);
	const maxTime = new Date().setHours(18, 0, 0);
	const [itemQty, setItemQty] = useState(0);
	const [dateTime, setDateTime] = useState(() => {
		let initialDate = minOrderDate.getDay() === 0 ? addDays(minOrderDate, 1) : minOrderDate;
		let initialDateTime = new Date(initialDate.setHours(14, 0, 0)); // Set time to 2:00 PM
		if (initialDateTime < minOrderDate) {
			initialDateTime = addDays(initialDateTime, 1);
		}
		return initialDateTime;
	});
	const [isCheckingOut, setIsCheckingOut] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [bSave, setbSave] = useState(localStorage.getItem(LS_USERINFO) != null);
	const [userInfo, setUserInfo] = useState(localStorage.getItem(LS_USERINFO) ? JSON.parse(localStorage.getItem(LS_USERINFO)) : {});
	const [open, setOpen] = useState(false);

	// FUNCTIONS
	const checkout = async (orderdata) => {
		const rawResponse = await fetch(`${path.ciHost}/index.php/api/v1/order/` + process.env.REACT_APP_STOREID, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(orderdata)
		});
		const content = await rawResponse.json();
		// console.log(content);
		return content;
	}

	const handleRemoveFromCart = (cart_index) => {
		const confirm = window.confirm("Would you like to remove this item from the cart?");
		if (confirm) {
			const newCart = cart.filter((item, idx) => idx !== cart_index);
			setCart(newCart);
			if (newCart.length == 0) {
				navigate('/menu');
			}
		}
	}

	const handleRemoveTopping = (cart_index) => {
		const confirm = window.confirm("Would you like to remove this topping from the cart?");
		if (confirm) {
			setCart(cart.map((item, idx) => idx === cart_index ? { ...item, topping: null } : item));
		}
	}

	const handleQty = (index, type) => {
		if (type === "minus") {
			const qty = cart[index].quantity;
			if (qty > 1) {
				setCart(cart.map((item, idx) => idx === index ? { ...item, quantity: item.quantity - 1 } : item));
			}
		} else {
			setCart(cart.map((item, idx) => idx === index ? { ...item, quantity: item.quantity + 1 } : item));
		}
	}

	const handleChangeCheckbox = (e) => {
		setIsConfirmed(e.target.checked);
	};

	// VALIDATION
	const CheckoutSchema = Yup.object().shape({
		firstname: Yup.string().required('firstname is required'),
		lastname: Yup.string(),
		email: Yup.string().email().required('email is required'),
		phone: Yup.number().required('phone is required'),
		//orderdate: Yup.string().required('orderdate is required'),
		//ordertime: Yup.string().required('ordertime is required'),
	});
	const formik = useFormik({
		initialValues: {
			firstname: userInfo.firstname ?? '',
			lastname: userInfo.lastname ?? '',
			email: userInfo.email ?? '',
			phone: userInfo.phone ?? '',
			//orderdate : new Date(),
			comment: ''
		},
		validationSchema: CheckoutSchema,
		onSubmit: async (values) => {
			const confirm = window.confirm("Would you like to place this order?");
			if (confirm) {
				setIsCheckingOut(true);
				// console.log(values);
				let detail = [];
				cart.map((item, index) => {
					detail.push({
						id: item.id,
						name: item.name,
						quantity: item.quantity,
						topping: JSON.stringify(item.topping),
						sale_price: item.sale_price,
						size: item.size,
						color: item.color ? item.color : ''
					});
				});
				const content = await checkout({
					store_id: 5,
					firstname: values.firstname,
					lastname: values.lastname,
					email: values.email,
					phone: values.phone,
					orderdetail: JSON.stringify(detail),
					totalamt: totalPrice,
					collection: Moment(dateTime).format("YYYY-MM-DD HH:mm"),
					comment: values.comment
				})
				if (content?.message === "success") {
					setCart([]);
					navigate("/checkout", { state: { orderReference: content.id } });
				}
				setIsCheckingOut(false);
			}
		},
	});

	const { errors, touched, handleSubmit, getFieldProps } = formik;

	// USE EFFECT
	useEffect(() => {
		setItemQty(cart.reduce((acc, item) => acc + item.quantity, 0));
		setTotalPrice(cart.reduce((acc, item) => {
			const itemTotal = item.sale_price * item.quantity;
			const toppingTotal = item?.topping ? item.topping.sale_price * item.quantity : 0;
			return acc + itemTotal + toppingTotal;
		}, 0));
	}, [cart]);

	const handleSaveCheckbox = (e) => {
		//console.log(bSave);
		//console.log(getFieldProps('firstname').value);
		if (!bSave) {
			const userInformation = {
				firstname: getFieldProps('firstname').value,
				lastname: getFieldProps('lastname').value,
				email: getFieldProps('email').value,
				phone: getFieldProps('phone').value,
			}
			localStorage.setItem(LS_USERINFO, JSON.stringify(userInformation));
		}
		else {
			localStorage.removeItem(LS_USERINFO);
		}
		setbSave(e.target.checked);
	};

	// RENDER
	return (
		<Box id="checkout-Box" sx={{ pt: 2 }}>
			{/* Basket Items */}
			<Stack sx={{ p: 2 }} spacing={2}>
				{/* <Typography variant="h3">Order Form</Typography> */}
				<Typography variant="h3">Order List {!isEmpty(cart) && `(${itemQty} item${itemQty > 1 ? "s" : ""})`}</Typography>
				<Box>
					<Stack
						direction="column"
						sx={{ py: 2, background: theme.palette.grey[300] }}
						spacing={2}>
						{cart.map((item, cart_index) => (
							<Stack key={`${item.id}-${cart_index}`} spacing={2}>
								{/* Product */}
								<Stack direction="row" spacing={2} sx={{ px: 2, "& .MuiTypography-root": { textTransform: "capitalize" } }}>
									{/* Image */}
									<Box sx={{ width: 80 }}>
										<img
											src={item.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${item.image}` : `${path.basename}/images/icon-product.svg`}
											//src={'http://localhost:4001/img/1c9ef244-3d51-5471-83f1-1e72fcb2a20d/'+item.image} 
											alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }}
										/>
									</Box>
									<Stack direction="column" sx={{ flexGrow: 1 }}>
										<Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
											<Typography variant="h5" sx={{ width: "70%" }}>
												{item.name}
											</Typography>
											<Typography variant="h5">£{fCurrency(item.sale_price * item.quantity)}</Typography>
										</Stack>
										<Typography variant="body1" gutterBottom>Size: {item.size}</Typography>
										{(item.color != '') && (
											<Typography variant="body1" gutterBottom>Color: {item.color}</Typography>
										)}
										<Stack direction="row" justifyContent="space-between">
											<Stack direction="row" sx={{ "& > *": { width: 20, height: 20, p: 0.2, justifyContent: "center", alignItems: "center" }, "& button": { background: theme.palette.background.default, border: "1px solid", borderColor: theme.palette.grey[900] } }}>
												<button onClick={() => handleQty(cart_index, "minus")}><Remove style={{ width: "100%", height: "100%" }} /></button>
												<Typography sx={{ fontSize: 12, border: "1px solid", display: "flex", justifyContent: "center", alignItems: "center" }}>{item.quantity}</Typography>
												<button onClick={() => handleQty(cart_index, "add")}><Add style={{ width: "100%", height: "100%" }} /></button>
											</Stack>
											<Button variant="text" onClick={() => handleRemoveFromCart(cart_index)}>
												<Typography variant="body1" sx={{ color: "black", textDecoration: "underline", fontWeight: "bold" }}>
													Remove
												</Typography>
											</Button>
										</Stack>
									</Stack>
								</Stack>
								{/* Toppings */}
								{item?.topping && (
									<Stack sx={{ px: 2 }}>
										<Divider sx={{ borderColor: theme.palette.grey[400] }}>Extra Toppings</Divider>
										<Stack direction="row" spacing={2} sx={{ pt: 2, "& .MuiTypography-root": { textTransform: "capitalize" } }}>
											{/* Image */}
											<Box sx={{ width: 80, minWidth: 80 }}>
												<img
													src={item.topping?.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${item.topping.image}` : `${path.basename}/images/icon-product.svg`}
													alt={item.topping?.name} style={{ width: "100%", height: "auto", objectFit: "cover" }}
												/>
											</Box>
											{/* Topping Details */}
											<Stack sx={{ flexGrow: 1 }}>
												<Typography sx={{ textAlign: "end" }} variant="body1">£{fCurrency(item.topping?.sale_price)}</Typography>
												<Typography variant="body1">{item.topping.note}</Typography>
												<Stack direction="row" sx={{ mt: "auto" }} justifyContent="flex-end">
													<Button variant="text" onClick={() => handleRemoveTopping(cart_index)}>
														<Typography variant="body1" sx={{ color: "black", textDecoration: "underline", fontWeight: "bold" }}>
															Remove
														</Typography>
													</Button>
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								)}
								<Stack spacing={0.5}>
									<Divider sx={{ borderColor: theme.palette.grey[500] }} />
									{/* <Divider sx={{ borderColor: theme.palette.grey[500] }} /> */}
								</Stack>
							</Stack>
						))}
						<Stack sx={{ px: 2 }} spacing={2}>
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="h5">Total</Typography>
								<Typography variant="h5">
									£{fCurrency(totalPrice)}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</Stack >
			{/* Form */}
			< FormikProvider value={formik} >
				<Form
					autoComplete='off'
					//noValidate
					onSubmit={handleSubmit}
				>
					<Stack sx={{ px: 2 }} spacing={2}>
						{/* <Typography variant="h3">Order Form</Typography> */}
						<Box sx={{ p: 2, background: theme.palette.grey[300] }}>
							<Stack direction="column" spacing={2}>
								<TextField
									type="text"
									label="First Name *"
									{...getFieldProps('firstname')}
									error={Boolean(touched.firstname && errors.firstname)}
									helperText={touched.firstname && errors.firstname}
								/>
								<TextField
									type="text"
									label="Last Name"
									{...getFieldProps('lastname')}
									error={Boolean(touched.lastname && errors.lastname)}
									helperText={touched.lastname && errors.lastname}
								/>
								<TextField
									label="Email *"
									{...getFieldProps('email')}
									error={Boolean(touched.email && errors.email)}
									helperText={touched.email && errors.email}
								/>
								<TextField
									label="Phone *"
									{...getFieldProps('phone')}
									error={Boolean(touched.phone && errors.phone)}
									helperText={touched.phone && errors.phone}
								/>
								<FormGroup sx={{ pl: 2 }}>
									<FormControlLabel
										control={<Checkbox color="secondary" checked={bSave} onChange={handleSaveCheckbox} />}
										label="Remember my contact info"
									/>
								</FormGroup>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										label="Collection Date"
										minDate={minOrderDate}
										shouldDisableDate={(date) => date.getDay() === 0}
										value={dateTime}
										onChange={(newDate) => setDateTime(newDate)}
										renderInput={(params) => <TextField {...params} />}
									/>
									<TimePicker
										label="Collection Time"
										minTime={minTime}
										maxTime={maxTime}
										value={dateTime}
										minutesStep={30}
										onChange={(newTime) => setDateTime(newTime)}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
								<TextField
									label="Comment/Note"
									{...getFieldProps('comment')}
									multiline
									rows={4}
								/>
							</Stack>
						</Box>
					</Stack>

					<Stack direction="row" spacing={2} justifyContent="center" alignItems="center" > 
						<FormGroup>
							<FormControlLabel
								control={<Checkbox color="secondary" checked={isConfirmed} onChange={handleChangeCheckbox} />}
								label={<>Accept our policy. </>}
							/>
						</FormGroup>
						<Typography onClick={ () => setOpen(true) } sx={{ 
							textDecoration: "underline",
							
						}}>
							Terms & condition
						</Typography>
					</Stack>
					{/* Checkout */}
					<Stack sx={{ p: 2 }} spacing={2}>
						{/* <Typography variant="h3">Checkout</Typography> */}
						{/* Checkout Button*/}
						<Button
							type='submit'
							//onClick={handleSubmit}
							fullWidth
							variant="outlined"
							disabled={isEmpty(cart) || isCheckingOut || !isConfirmed}
							sx={{
								borderRadius: 0,
								borderColor: theme.palette.buttonBlack.main,
								"&:hover": { background: theme.palette.buttonBlack.main, borderColor: theme.palette.buttonBlack.main },
								opacity: isCheckingOut || isEmpty(cart) || !isConfirmed ? 0.5 : 1,
								p: 0.3,
							}}>
							<Box
								sx={{
									border: "1px solid",
									borderColor: theme.palette.buttonBlack.main,
									width: "100%",
									height: "100%",
									background: theme.palette.buttonBlack.main,
									color: theme.palette.background.default,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Typography sx={{ p: 1.5, fontWeight: "bold" }}>Submit your order</Typography>
							</Box>
						</Button>
					</Stack>
				</Form>
			</FormikProvider >
			<Modal
				open={open}
				onClose={()=>setOpen(false)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={style}>
					<IconButton
						aria-label="close"
						onClick={()=>setOpen(false)}
						sx={{
							position: 'fixed',
							right: 16,
							top: 16,
							zIndex:2000,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
					<Box sx={{ width:'100%', height:'100%', p:4, overflow:'scroll'}}>
						<TermsAndConditions />
					</Box>
				</Box>
			</Modal>
		</Box >
	);
};

export default CheckoutForm;
