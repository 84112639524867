import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import StoreIcon from '@mui/icons-material/Store';
import { EmailOutlined, Phone, Sms } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { StoreContext } from ".";
import { useContext } from "react";

export const snsLink = (url, size = '3em') => {
  if (!url) return <></>
  if (url.indexOf('facebook') >= 0) {
    return (
      <a href={url} target="_blank" color="inherit">
        <FacebookIcon sx={{ fontSize: size }} />
      </a>
    )
  }
  else if (url.indexOf('instagram') >= 0) {
    return (
      <a href={url} target="_blank" color="inherit">
        <InstagramIcon sx={{ fontSize: size }} />
      </a>
    )
  }
  else return <></>
}

const footermenu = [
  { path: '/about', name: 'About Us' },
  // { path: '/contact', name: 'Contact Us' },  
  { path: '/privacy', name: 'Privacy Policy' },
  { path: '/terms', name: 'Terms & conditions' },
];
const Footer = () => {
  const { store } = useContext(StoreContext);
  const theme = useTheme();

  return (
    <Box sx={{
      backgroundColor: '#262626', //theme.palette.background.dark,
      color: '#cccccc',
      lineHeight: '2em',
      pb: '2em',
    }}>
      <Container maxWidth='lg' sx={{ px: { xs: 0, md: '4em' } }}>
        {/* <hr style={{marginBlockStart:0}} /> */}
        <Box display='flex' width='100%' sx={{ justifyContent:'center', py:'4em' }}>
          <img src={process.env.PUBLIC_URL + '/images/jubilee/logo-white.png'} width='200' />
        </Box>
        <Typography variant="body1" sx={{ width:'100%', textAlign:'center' }}>
          &copy;2024 Jubilee Hair Ltd. - All rights reserved  
        </Typography>
        {/* <Box sx={{ pl: { xs: '0', md: '1em' }, textAlign: 'left' }}>
          {
            footermenu?.map((menu, index) => (
              <Link key={index} to={menu.path}>
                <Typography
                  variant="h5"
                  fontFamily="sans-serif"
                  fontSize='1.5em'
                  sx={{ py: '0.5em', color:'inherit', px: 2 }}
                >
                  {menu.name}
                </Typography>
              </Link>
            ))
          }
        </Box> */}
        {/* <Divider />
        <Box display='flex' sx={{ 
          justifyContent: 'space-between', alignItems: 'center', py: '1em', px: 2 
        }}>
          <Typography variant="h3" color='inherit'>{store.company}</Typography>
          <Box>
            {snsLink(store.sns)}
            &nbsp;&nbsp;&nbsp;
            {snsLink(store.sns2)}
          </Box>
        </Box>
        <Box display='flex' sx={{ justifyContent: 'space-between', px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Phone />
            <Typography variant="body1" sx={{ pl:'0.5em' }} >{store.phone}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Sms/>
            <Typography variant="body1" sx={{ pl:'0.5em' }}>{store.mobile}</Typography>
          </Box>
        </Box>
        <Box display='flex' sx={{ alignItems: 'center', justifyContent: 'space-between', px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EmailOutlined />
            <Typography variant="body1" sx={{ pl:'0.5em' }}>{store.email}</Typography>
          </Box>
        </Box>
        <Box display='flex' sx={{ alignItems: 'center', justifyContent: 'space-between', px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StoreIcon />
            <Typography variant="body1" sx={{ pl:'0.5em' }}>{store.address}</Typography>
          </Box>
        </Box> */}
      </Container>
    </Box>
  )
};

export default Footer;