import { useEffect, useState } from "react";
import { forEach } from "lodash";
import { isMobile } from "react-device-detect";
// material
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
// graphql
import { useQuery } from "@apollo/client";
import { BRANDS_QUERY } from "graphql/query";

const Brand = ({ value, required = false, requiredMark = "", message = "", allitem = false, handleChange }) => {
	// data
	const [brand, setBrand] = useState(allitem ? "all-items" : "");
	const [brands, setBrands] = useState();
	// graphql
	const { loading, error } = useQuery(BRANDS_QUERY, {
		onCompleted: (data) => {
			if (data.brands.response.status) {
				const items = [];
				forEach(data.brands.items, (brand) => {
					if (brand.brand) {
						items.push({
							value: brand.brand.toLowerCase(),
							label: brand.brand.toUpperCase(),
						});
					}
				});
				setBrands(items);
			}
		},
	});

	useEffect(() => {
		if (value || value === "") setBrand(value);
	}, [value]);

	if (loading) return <>Loading...</>;
	if (error) return <>ERROR...</>;

	return (
		<FormControl variant={isMobile ? "standard" : "outlined"} sx={{ minWidth: 120 }} error={required}>
			<InputLabel>BRANCH {requiredMark}</InputLabel>
			{brands && (
				<Select label={`BRANCH ${requiredMark}`} autoWidth value={brand} onChange={handleChange}>
					{allitem && <MenuItem value="all-items">ALL BRANCH</MenuItem>}
					{brands.map((brand, index) => (
						<MenuItem value={brand.value} key={index}>
							{brand.label}
						</MenuItem>
					))}
				</Select>
			)}
			<FormHelperText>{message}</FormHelperText>
		</FormControl>
	);
};

export default Brand;
