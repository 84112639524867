import { Typography } from "@mui/material";
import { useContext } from "react";
import { StoreContext } from "layout/HomeLayout";

export const TermsAndConditions = () => {
  const { store } = useContext(StoreContext); 
  return (
    <>
      <Typography variant="h1" sx={{ pb:'0.5em'}}>Term & Condition</Typography>
      <hr />
      <Typography variant="h3" sx={{ py:'0.5em'}}>Cancellations Policy</Typography>
      <Typography variant="body1">
        Jubilee hair salon requires 24 hours' notice for hair cuts, and a 48 hours' notice for chemical treatments<br/>
        If you have paid the deposit for the appointment, let us know by text {store.mobile} ,in advance at least 48 hours before about cancelling your appointment and we will give you the refund for the deposit. <br/><br/>
        If you tell us that you cannot attend your appointment after 24hours, we cannot give you the refund of the deposit. In addition to this, if you come 15 minutes or later to your allocated appointment, we cannot process your hair appointment and also cannot give you the refund of your deposit. <br/><br/>
      </Typography>

      <Typography variant="h3" sx={{ pb:'0.5em'}}>Clients Notice<br/></Typography>      
      <Typography>
        Depending on the length and thickness of hair, prices may change. Clients with thicker or longer hair may charge more. We cannot carry out perm on clients who already have highly damaged or bleached hair. However, if you really want to get it done; you have to visit the salon in advance for a consultation. <br /><br />
      </Typography>

      <Typography variant="h3" sx={{ pb:'0.5em'}}>Perm and Straightening Policy<br/></Typography>      
      <Typography>
        If you come to your appointment and find out that your hair is not suitable on the day for the process without having your hair checked via text message on {store.mobile} or visiting the salon, the deposit is non refundable. <br /><br />
      </Typography>
    </>
  )
}
