export const colors = {
	// paper & background
	paper: "#ffffff",
	//paper: "#eceae6",
	// primary
	primaryLight: "#e3f2fd",
	primary200: "#90caf9",
	primaryMain: "#2196f3",
	primaryDark: "#1e88e5",
	primary800: "#1565c0",
	// secondary
	secondaryLight: "#e8d8c3", //"#ede7f6",
	secondary200: "#b39ddb", //"#b39ddb",
	secondaryMain: "#d2b48c", //"#673ab7",
	secondaryDark: "#bc9055", //"#5e35b1",
	secondary800: "#d2b48c", //"#4527a0",
	// success
	successLight: "#b9f6ca",
	success200: "#69f0ae",
	successMain: "#00e676",
	successDark: "#00c853",
	// error
	errorLight: "#ef9a9a",
	errorMain: "#f44336",
	errorDark: "#c62828",
	// orange
	orangeLight: "#fbe9e7",
	orangeMain: "#ffab91",
	orangeDark: "#d84315",
	// warning
	warningLight: "#fff8e1",
	warningMain: "#ffe57f",
	warningDark: "#ffc107",
	// grey
	grey50: "#fafafa",
	grey100: "#f5f5f5",
	grey200: "#eeeeee",
	grey300: "#e0e0e0",
	grey500: "#9e9e9e",
	grey600: "#757575",
	grey700: "#616161",
	grey900: "#212121",
	//green
	greenLight: "#03b746",
	greenMain: "#039138",
	greenDark: "#015d24",
	// black
	blackLight: "#2e2e2e",
	blackMain: "#1b1b1b",
	blackDark: "#000000",

	// ==============================|| DARK THEME VARIANTS ||============================== //
	// paper & background
	darkPaper: "#111936",
	darkBackground: "#1a223f",
	// dark 800 & 900
	darkLevel1: "#29314f",
	darkLevel2: "#212946",
	// text variants
	darkTextTitle: "#d7dcec",
	darkTextPrimary: "#bdc8f0",
	darkTextSecondary: "#8492c4",
	// primary dark
	darkPrimaryLight: "#e3f2fd",
	darkPrimaryMain: "#2196f3",
	darkPrimaryDark: "#1e88e5",
	darkPrimary200: "#90caf9",
	darkPrimary800: "#1565c0",
	// secondary dark
	darkSecondaryLight: "#d1c4e9",
	darkSecondaryMain: "#7c4dff",
	darkSecondaryDark: "#651fff",
	darkSecondary200: "#b39ddb",
	darkSecondary800: "#6200ea",
};
