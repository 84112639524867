import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Button, useMediaQuery } from "@mui/material"
import ImageBox from "components/ui/ImageBox";

const About = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display='block' sx={{ textAlign:'center'}}>
      <Box>
        <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/pic1.jpg'} isBg={true} opc={0}>
          <Box display="flex" height="200px" width="100%" sx={{ 
            py:'1em', 
            alignItems:'center',
            justifyContent:'center',
          }}>
            <Typography variant="span" sx={{ 
              //fontFamily: 'Rochester, cursive', 
              fontSize: '5em', 
              lineHeight: '2em',
              color : 'white'
            }}>
              About Us
            </Typography>
          </Box>
        </ImageBox>
      </Box>
      <Box sx={{ 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        flexDirection:'column',
      }}>
        <ImageBox imageUrl='./images/jubilee/pic1.jpg' isBg={true} opc={1.0}>          
          
          <Box>
            <Typography variant="body2" sx={{
              px:{xs:'2em', md:'5em'},
              fontSize:'1.8em',
              textAlign:'center',
              pb:'1em',
              color : 'black',
              //fontFamily:'sans-serif',
            }}>
            <br/>
              Since opening in 2004, Jubilee Hair Salon has quickly grown to become one of the premier salons in the area. We pride ourselves on staying true to the fundamentals while continuously striving to offer the latest hair trends to our clients.Our signature services include haircuts, Magic Straightening, and the renowned Korean Digital Perm. We also offer a range of coloring services, from classic dyeing using products like Wella and Japan’s Milbon and Dem, to fashion colors. For men, we specialize in a trendy perm that combines perm and down perm techniques.At Jubilee Hair Salon, our goal is to deliver the best results you can experience in London.
            </Typography>
          </Box>
        </ImageBox>
      </Box>  
      {/* <Box>
        <ImageBox imageUrl='./images/choice/macarongbg.webp' isBg={true} opc={0}>
          <Box width='auto' height='100%' display='flex' sx={{
            flexDirection:'column',
            justifyContent:'flex-end',
            py:'1em',
            backgroundColor: `rgba(255, 255, 255, 0.5)`
            }}>          
            <Typography variant='h2'>
              @choicecakencoffee
            </Typography>
            <Typography variant='h3' sx={{ p:'1em' }}>
              Please see the most recent update through Instagram.
            </Typography>
            <Box>
              <Button 
                href="https://www.instagram.com/choicecakencoffee_cafe/" 
                target='_blank'
                variant='contained' 
                color='secondary'
                sx={{ width : 'auto'}} 
              >
                Following Us
              </Button>
            </Box>
          </Box>
        </ImageBox>
      </Box> */}
    </Box>
  );
};

export default About;