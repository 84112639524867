// icon
import InventoryIcon from '@mui/icons-material/Inventory';
import { Person } from '@mui/icons-material';
// config
import { path } from 'config/path';

const menu = {
  // title: 'Inventory',
  type: 'group',
  level: 'client',
  children: [
    {
      id: 'items',
      title: 'Staffs',
      // type: 'collapse',
      type: 'item',
      url: path.urls.inventoryItems,
      icon: Person,
      breadcrumbs: true,
      // children: [
      //   {
      //     id: 'orders',
      //     title: 'Orders',
      //     type: 'item',
      //     url: path.urls.inventoryOrders,
      //   },
      //   {
      //     id: 'items',
      //     title: 'Menu',
      //     type: 'item',
      //     url: path.urls.inventoryItems,
      //   },
    },
  ],
};

export default menu;
