// material
import { Button, Typography } from '@mui/material';

const BookBtn = () => {
  return (
    <Button 
      href="https://s-iq.co/BookingPortal/dist/?salonid=fa588f35-419e-4272-8215-3997fe4ff544&tab=book" 
      target='_blank'
      variant='contained' 
      sx={{ mb:'1em', backgroundColor:'black', color:'white'}} 
    >
      <Typography sx={{ fontWeight:'800', p:'1em'}}>
        BOOK ONLINE
      </Typography>
    </Button>
  );
};

export default BookBtn;