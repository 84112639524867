import { 
	Container, Box, Typography, List, 
	ListItem, Badge, IconButton, Button
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { checkLoggedIn } from "helpers/storage";
import { useTheme } from "@mui/material/styles";
import { useState, useContext } from "react";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { Cart } from "components/page/menu";
import { StoreContext } from ".";
import BookBtn from "components/ui/bookbtn";

const menuitems = [
	{ title: "Our Services & Price", to: "/price" },
	{ title: "Our Staff", to: "/staff" },
	{ title: "Terms & Conditions", to: "/terms" },
	// { title: "About Us", to: "/about" },
];

const SideMenu = ({ bLogin, setIsOpen, theme }) => {
	const handleClose = () => {
		setIsOpen(false);
	};

	const mobileItems = menuitems?.map((menu, index) => {
		return (
			<ListItem key={index} sx={{ width: "100%", px: "3.2em" }} onClick={handleClose}>
				<Link to={menu.to} style={{ width: "100%" }}>
					<Typography sx={{ fontSize: "1.8em", fontWeight: 400, lineHeight: "2.7em", letterSpacing: "-.018em" }}>{menu.title}</Typography>
				</Link>
			</ListItem>
		);
	});

	return (
		<Box
			sx={{
				top: 0,
				height: "100%",
				width: "100%",
				py: "4em",
				// background:'white',
				background: theme.palette.background.default,
			}}>
			{/* <Box sx={{ textAlign:'left', px:'3.2em' }}>
				<BookBtn />
			</Box> */}
			<List>{mobileItems}</List>
			{ bLogin ? (
				<>
					<hr />
					<Box sx={{ width: "100%", px: "3.2em", mt: "1.5em", textAlign: "left" }}>
						<Link to="/store/staffs" style={{ width: "100%" }}>
							<Typography sx={{ fontSize: "1.8em", fontWeight: 400, lineHeight: "2.7em", letterSpacing: "-.018em" }}>Dashboard</Typography>
						</Link>
					</Box>
				</>
			) : (
				<>
					{/* <hr />
					<Box sx={{ width: "100%", px: "3.2em", mt: "1.5em", textAlign: "left" }}>
						<Link to="/login" style={{ width: "100%" }}>
							<Typography sx={{ fontSize: "1.8em", fontWeight: 400, lineHeight: "2.7em", letterSpacing: "-.018em" }}>Admin</Typography>
						</Link>
					</Box> */}
				</>
			)}
		</Box>
	);
};

const Header = () => {
	const theme = useTheme();
	const { cart } = useContext(StoreContext);
	const bLogin = checkLoggedIn();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenCart, setIsOpenCart] = useState(false);

	const items = menuitems?.map((menu, index) => {
		return (
			<ListItem key={index} sx={{ p: 0, mr: "1.6em", height: "100%", width:'auto' }}>
				<Link to={menu.to}>
					<Typography sx={{ fontSize: "1.3em", fontWeight: 700, textTransform: "uppercase", letterSpacing: ".13em" }}>{menu.title}</Typography>
				</Link>
			</ListItem>
		);
	});

	const toggleDrawer = () => {
		setIsOpen(!isOpen);
	};

	const toggleCart = () => {
		setIsOpenCart(!isOpenCart);
	};

	const closeDrawer = () => {
		setIsOpen(false);
		setIsOpenCart(false);
	};

	const handleClick = (event) => {
		const container = event.currentTarget.getBoundingClientRect();
		const clickX = event.clientX - container.left;
		// Define the width of the sidebar and the clickable area outside the sidebar
		const clickableAreaWidth = container.width * 0.2;
		// Check if the click is within the first 20% of the screen width and outside the sidebar
		if (clickX >= 0 && clickX <= clickableAreaWidth) {
			setIsOpen(false);
			setIsOpenCart(false);
		}
	};

	return (
		<Box
			display="block"
			sx={{
				position: "fixed",
				left: 0,
				top: 0,
				zIndex: 999,
				width: "100%",
				// backgroundColor:'white',
				backgroundColor: theme.palette.background.default,
				textAlign: "center",
				boxShadow: "0 .2em .2em 0 rgba(0,0,0,.12)",
			}}>
			<Container maxWidth="lg">
				<Box component="header">
					<Box
						component="nav"
						sx={{
							display: "flex",
							width: "100%",
							height: { xs: "7.2em", md: "10em" },
							alignItems: "center",
							px: { xs: 0, md: "2em" },
						}}>
						<Link to="/" onClick={closeDrawer}>
              <Box display='flex' sx={{ }}>
                <img src={process.env.PUBLIC_URL + '/images/jubilee/logo-jubilee.png'} width='200' />
              </Box>
						</Link>
						<Box
							height="100%"
							width="100%"
							display="flex"
							sx={{
								pl: "4em",
								alignItems: "center",
								justifyContent: "flex-end",
							}}>
							<Box
								sx={{
									display: { xs: "none", md: "flex" },
									height: "100%",
									width: " 100%",
									alignItems: "center",
									justifyContent: "space-between",
								}}>
								<List sx={{ display: "flex", alignItems: "center", pl: "2em" }}>{items}</List>
								{/* <Box display="flex" sx={{ mb:0 }}>
									<BookBtn />
								</Box> */}
							</Box>
							
							<Box display="flex" sx={{ justifyContent: "center" }}>
								{/* {(!isOpen && (cart.length>0)) && (
									<IconButton onClick={toggleCart}>
										{isOpenCart ? (
											<CloseIcon />
										) : (
											<Badge badgeContent={cart.reduce((acc, item) => acc + item.quantity, 0)} color="primary">
												<ShoppingCartOutlined />
											</Badge>
										)}
									</IconButton>
								)} */}
								{ bLogin && (
									<Box sx={{ display: { xs: "none", md: "flex" }, mt: "0.5em", pl:'1em' }}>									
										<Link to="/store/staffs">
											<DashboardIcon />
										</Link>									
									</Box> 
								)}
								{!isOpenCart && (
									<IconButton onClick={toggleDrawer} sx={{ display: { xs: "block", md: "none" } }}>
										{isOpen ? <CloseIcon /> : <MenuIcon />}
									</IconButton>
								)}
							</Box>
						</Box>
					</Box>
					<Box
						onClick={handleClick}
						display="block"
						sx={{
							position: isOpen || isOpenCart ? "fixed" : "inherit",
							right: 0,
							left: 0,
							top: "7.2em",
							bottom: 0,
							zIndex: 5,
							"&::before": {
								content: '""',
								display: "block",
								position: "absolute",
								width: isOpen || isOpenCart ? "100%" : "0",
								height: "100%",
								top: 0,
								opacity: isOpen || isOpenCart ? 1 : 0,
								background: "rgba(0, 0, 0, .38)",
								transition: "opacity .3s linear",
							},
						}}>
						<Box
							sx={{
								height: "100vh",
								width: "80%", // isOpen ? '80%' : 0,
								right: isOpen ? 0 : "-700px",
								position: "absolute",
								transition: "right 0.3s linear",
								display: { xs: "flex", md: "none" },
							}}>
							<SideMenu bLogin={bLogin} setIsOpen={setIsOpen} theme={theme} />
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default Header;
